import * as React from 'react'
import css from '../Cart/Item.scss'
import quicklookModalCss from './QuicklookModal.scss'
import Modal from '../../../Common/Modal'
import ThemedButton from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Button/Button'
import { THEME_BUTTON_TYPES } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Theme/ThemeWrapper'
import VerticalCarousel from '../PDP/VerticalCarousel/VerticalCarousel'
import LoadingIndicator from '../../../Common/LoadingIndicator/LoadingIndicator'
import { useState, useEffect } from 'react'
import TopProductInfo from '../PDP/TopProductInfo'
import { ProductAvailability, renderIncludesAndMeasureWarningMessage } from '../PDP/ProductDetailRenderProps'
import LocationContext, { ILocationContextProviderProps } from '../../Context/LocationContext'
import { IGersPriceAndInventorySummary, PriceType } from '../../Services/GERS/Pricing/inventory'
import { GERS_API_CALLS } from '../../Services/GERS'
import { IUnbxdProduct } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/unbxd/unbxdInterfaces'

export interface IQuicklookModalProps {
  onClose: () => void
  product: IUnbxdProduct
}

const QuicklookModal = ({ onClose, product }: IQuicklookModalProps) => {
  const [loading, setLoading] = useState(true)
  const [customerMessage, setCustomerMessage] = React.useState('')
  const { myClosestStore, showLocationSelect, setShowLocationSelect } = React.useContext(
    LocationContext.Context,
  ) as ILocationContextProviderProps
  const [displayRatingSummary, setDisplayRatingSummary] = React.useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  useEffect(() => {
    window.bvCallback = (BV: any) => {
      const reviewSummary = BV?.rating_summary?.data?.find(
        (d: { data: { reviewSummary: any } }) => d?.data?.reviewSummary,
      )
      if (reviewSummary) {
        const numReviews = reviewSummary?.data?.reviewSummary?.numReviews
        if (numReviews && numReviews > 0) {
          setDisplayRatingSummary(true)
        }
      }
    }
  }, [])

  useEffect(() => {
    const getInventoryData = async () => {
      const response = await getInventory(product.sku, myClosestStore?.legacyStoreId)
      if (response?.length) {
        setCustomerMessage('')
        setProductInventory(response[0])
      }
    }

    getInventoryData()
  }, [])

  const setProductInventory = (priceInventorySummary?: IGersPriceAndInventorySummary) => {
    if (priceInventorySummary) {
      const { customerMessage } = priceInventorySummary
      setCustomerMessage(customerMessage)
    }
  }

  const getInventory = async (sku: string, storeCode?: string): Promise<IGersPriceAndInventorySummary[] | []> => {
    if (!storeCode) {
      return []
    }
    const priceInventorySummary = await GERS_API_CALLS.INVENTORY.getBatchPriceAndInventoryBySku({
      priceType: PriceType.WEB,
      storeCode,
      skus: [sku],
    })
    return priceInventorySummary.data || []
  }

  const renderImageCore = () => {
    return (
      <div className={quicklookModalCss.contentContainer}>
        <div className={css.thumbnailCarousel}>
          <VerticalCarousel
            defaultImage={'https://via.placeholder.com/800x800'}
            sliderData={product.images.items}
            horizontal={true}
          />
        </div>
      </div>
    )
  }

  const renderTopProductInfo = () => {
    return (
      <div className={css.topProductInfo}>
        <TopProductInfo product={product} displayRatingSummary={displayRatingSummary} />
      </div>
    )
  }

  const renderAvailability = () => {
    return (
      <div style={{ paddingBottom: '10px' }}>
        <ProductAvailability
          customerMessage={customerMessage}
          myClosestStore={myClosestStore}
          setShowLocationSelect={setShowLocationSelect}
          showLocationSelect={showLocationSelect}
        />
      </div>
    )
  }

  return (
    <Modal
      onClose={onClose}
      width="1000px"
      height="570px"
      isCloseTextBold={true}
      isTitleBackgroundGrey={false}
      isOverFlowing={true}
    >
      {loading && <LoadingIndicator style={{ width: '50px', position: 'relative', top: '190px' }} />}
      <div style={loading ? { display: 'none' } : { display: 'flex' }} className={quicklookModalCss.quicklookContainer}>
        {renderImageCore()}
        <div className={quicklookModalCss.rightColumn}>
          {renderTopProductInfo()}
          {renderAvailability()}
          <div style={{ marginTop: '15px' }}>{renderIncludesAndMeasureWarningMessage(product, true)}</div>
          <div style={{ paddingTop: '10px' }}>
            <ThemedButton
              onClick={() => (location.href = product.productUrl)}
              fullWidth={true}
              type={THEME_BUTTON_TYPES.PRIMARY}
            >
              More Details
            </ThemedButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default QuicklookModal
