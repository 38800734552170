import * as React from 'react'
import css from './ProductThumbnailContainer.scss'
import { IProduct } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/PDP'
import MorPriceDisplay from '../PDP/MorPriceDisplay/MorPriceDisplay'
import { fit } from '../../../../utils/imageProxy'
import {
  clearDataLayerEcommerce,
  DATA_LAYER_LIST_NAME,
  DATA_LAYER_LOCATION_ID,
  onProductClick,
} from '../../../../utils/dataLayerController'
import Thumbnail from '../Common/Thumbnail'
import { IUnbxdProduct } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/unbxd/unbxdInterfaces'
import {
  getCurrentProductFromVariantsPLPs,
  getLowestAndHighestFinalPriceFromVariants,
} from '../../../Utils/productUtils'
import MoreOptionsAvailable from './MoreOptionsAvailable'
import { ICatalogConfig } from '../Catalog/Catalog'
import { IMorUnbxdProduct, IVariant } from '../../../Utils/unbxdUtils'
import { capitalize } from '../../../Utils/customProductUtils'
import ColorWheel from '../Common/Images/ColorWheel'
import CustomProductBadge from './CustomProductBadge'
import QuicklookModal from './QuicklookModal'
import { useState } from 'react'

export interface IProductThumbnailContainerProps {
  parent: IMorUnbxdProduct
  productGroup: IUnbxdProduct[]
  variantCount: number
  defaultThumbnail?: string
  configuration: ICatalogConfig
  index: number
  isSalePage: boolean
  isSearchPage: boolean
}

const ProductThumbnailContainer = (props: IProductThumbnailContainerProps) => {
  const { parent, productGroup, isSalePage, variantCount, index } = props
  const [showQuicklook, setShowQuicklook] = useState(false)
  const [showHoverImage, setShowHoverImage] = useState(false)
  const getDefaultThumbnail = (): string => {
    const thumbnail = selectedProduct.thumbnail || props.defaultThumbnail
    return thumbnail
      ? fit(thumbnail, {
          size: 300,
        })
      : '/static/morPlaceholder.jpg'
  }

  const getHoverThumbnail = (): string | null => {
    const hoverImage = selectedProduct.images.items.filter(image => image.alt.endsWith('_hover'))
    return hoverImage.length > 0
      ? fit(hoverImage[0].url, {
          size: 300,
        })
      : null
  }

  const goToProduct = (product: IProduct) => {
    const { configuration, index } = props
    clearDataLayerEcommerce()
    const listName = props.isSearchPage
      ? DATA_LAYER_LIST_NAME.SEARCH_RESULTS
      : props.isSalePage
      ? DATA_LAYER_LIST_NAME.SALE_RESULTS
      : DATA_LAYER_LIST_NAME.CATALOG_BROWSE
    onProductClick(product as IUnbxdProduct, index, listName, DATA_LAYER_LOCATION_ID.PRODUCT_LISTING_PAGE) //GA onProductClick event tracking
    onUnbxdProductClick(product)
    if (configuration.events && configuration.events.goToProduct) {
      configuration.events.goToProduct(product)
      return
    }
  }

  const getURLToProduct = (product: IProduct): string => {
    const { configuration } = props
    if (configuration.events && configuration.events.getURLToProduct) {
      return configuration.events.getURLToProduct(product)
    }
    return ''
  }

  const onUnbxdProductClick = (product: IProduct) => {
    const { configuration } = props
    if (configuration.events && configuration.events.onUnbxdProductClick) {
      configuration.events.onUnbxdProductClick(product, index)
      return
    }
  }

  const selectedProduct = getCurrentProductFromVariantsPLPs(productGroup, parent.relevantDocument)
  const hoverThumbnail = getHoverThumbnail()
  const selectedProductVariant = selectedProduct as IVariant

  const classes = [css.productThumbnail]

  const imgAltText = isSalePage ? `${selectedProduct.name} on sale` : selectedProduct.name

  return (
    <div
      className={classes.join(' ')}
      onMouseEnter={() => setShowHoverImage(true)}
      onMouseLeave={() => setShowHoverImage(false)}
    >
      {selectedProductVariant.CUSTOM_SKU === 'Y' && (
        <div className={`${css.customizeContainer}`}>
          <ColorWheel style={{ width: '20px', height: '20px' }} />
          <div className={`${css.customizeLabel}`}>Customize</div>
        </div>
      )}
      <div className={css.thumbnailInner}>
        <a href={getURLToProduct(selectedProduct)} onClick={() => goToProduct(selectedProduct)}>
          <Thumbnail
            ariaLabel={imgAltText}
            imgAltText={imgAltText}
            imgUrl={showHoverImage && hoverThumbnail ? hoverThumbnail : getDefaultThumbnail()}
            containerClass={css.imageContainer}
            objectFitCover
          />
          <div className={css.thumbnailBadges}>
            <CustomProductBadge
              containerClassName={css.badgeContainer}
              value={selectedProduct.DELIVERY_QUALIFICATION}
            />
            <CustomProductBadge containerClassName={css.badgeContainer} value={selectedProduct.VENDOR_TYPE} />
          </div>
          <div className={css.thumbnailDetails}>
            <div className={css.thumbnailTitle}>
              <div>
                {parent.relevantDocument !== 'variant'
                  ? capitalize(parent?.parentName?.toLowerCase())
                  : selectedProduct.name}
              </div>
            </div>
            <MorPriceDisplay
              product={selectedProduct}
              lowestHighestFinalPrice={
                parent.relevantDocument !== 'variant' && variantCount > 1
                  ? getLowestAndHighestFinalPriceFromVariants(productGroup)
                  : null
              }
              showWasLabel
              showSeePriceInCartLabel
            />
          </div>
          <MoreOptionsAvailable variantCount={variantCount} goToProduct={goToProduct} product={selectedProduct} />
        </a>
        <div onClick={() => setShowQuicklook(true)} className={css.quicklookBtn}>
          Quicklook
        </div>
      </div>
      {showQuicklook && <QuicklookModal onClose={() => setShowQuicklook(false)} product={selectedProduct} />}
    </div>
  )
}

export default ProductThumbnailContainer
