import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import css from './ColorSwatch.scss' // Import your CSS module
import { getColorHex } from '../../../../Utils/navigationUtils'

const ColorSwatch = ({ item, isChecked, onAttributeClickEvent, query, section, type, isBright }) => {
  const getClassName = () => {
    if (type === 'single') {
      return css.colorSwatch
    }

    if (type === 'custom') {
      return css.customColorSwatch
    }

    if (type === 'multi') {
      return css.multiColorSwatch
    }
  }
  return (
    <span
      onClick={() => onAttributeClickEvent(query, section.label)}
      className={`${getClassName()} ${isBright ? css.isBright : ''} ${isChecked() ? css.checked : ''}`}
      style={type === 'single' ? { backgroundColor: getColorHex(item.value) } : {}}
    >
      {isChecked() && (
        <FontAwesomeIcon
          icon={faCheck}
          color="white"
          style={{
            width: '14px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            stroke: 'black',
            strokeWidth: 20,
          }}
        />
      )}
    </span>
  )
}

export default ColorSwatch
