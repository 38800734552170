import * as React from 'react'
import css from './Sort.scss'
import SortByDropdown from './SortByDropdown'

export enum IProductSortDirection {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export interface IProductsSortOption {
  direction?: IProductSortDirection
  label: string
  value: string
}
export interface IProps {
  productSortConfiguration?: {
    options: IProductsSortOption[]
    active: string
  }
  query: any
  shownItems: number
  maxItems: number
  onChange: (option: IProductsSortOption) => void
}

export default class Filters extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props)
  }

  public renderFiltersRowContainer = () => {
    const { shownItems, maxItems, productSortConfiguration } = this.props
    return (
      <div className={css.filterRow}>
        <div className={css.resultsMessage}>
          Showing 1-
          {shownItems > maxItems ? maxItems : shownItems} of {maxItems}
        </div>
        <div className={css.desktopSortBy}>
          <SortByDropdown productSortConfiguration={productSortConfiguration} onChange={this.props.onChange} />
        </div>
      </div>
    )
  }
  public render() {
    return <React.Fragment>{this.renderFiltersRowContainer()}</React.Fragment>
  }
}
