import * as React from 'react'
import CategoryThumbnails from './CategoryThumbnails/CategoryThumbnails'
import CatalogProducts from './Products/Products'
import CatalogNavigation, { ICatalogNavigationItem, IFilterItem } from './Navigation/Navigation'
import { IProductsSortOption } from './Products/Sort/Sort'
import { ICategory } from '.'
import { Row, Col, Container } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Layout'
import { has, isEmpty } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/lodash'
import { IProduct } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/PDP'
import css from './Catalog.scss'
import nCss from './Navigation/Navigation.scss'
import LoadingIndicator from '../../../Common/LoadingIndicator/LoadingIndicator'
import { decodeAmpersandOnUrl } from '../../../../utils/unbxd/unbxdHelpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

export interface ICatalogResponse {
  navigation: {
    attributes: ICatalogNavigationItem[]
  }
  subcategories: ICategory[]
  products: IProduct[]
  usePriceDivider?: boolean
}

export interface ICatalogConfig {
  events: {
    goToProduct?: (product: IProduct) => void
    getURLToProduct?: (product: IProduct) => string
    addToCart?: (product: IProduct) => void
    goToCategory?: (category: ICategory) => void
    onAttributeFilterClick?: (query: string, category: any) => void
    getURLAttributeFilter?: (query: string, category: any) => string
    onRemoveFilter?: (key: string, category: any) => void
    onProductSort?: (sort: IProductsSortOption) => void
    onUnbxdProductClick?: (product: IProduct, index: number) => void
  }
  renderProps?: {
    renderAtcButton?: (product: IProduct) => JSX.Element
    renderProductThumbnailContainer?: (product: IProduct) => JSX.Element
    renderBottomSection?: () => JSX.Element
  }
}

export interface IProps {
  query: { [x: string]: any }
  data: ICatalogResponse
  configuration: ICatalogConfig
  category: ICategory | null
  filtersList?: IFilterItem[]
  maxItems?: number
  defaultThumbnail?: string
  productSortConfiguration?: {
    options: IProductsSortOption[]
    active: string
  }
  showResultsMessage?: boolean
  loading?: boolean
}

/**
 * Will render all search results
 */
export default class CatalogSearch extends React.Component<IProps, any> {
  public renderBody = () => {
    const { query, configuration, data, category, showResultsMessage, loading } = this.props
    const { products, subcategories, usePriceDivider } = data
    const matching = { ...query }
    delete matching.slug
    return (
      <>
        {isEmpty(matching) && (
          <React.Fragment>
            <CategoryThumbnails categories={subcategories} configuration={configuration} />
          </React.Fragment>
        )}
        {loading && <LoadingIndicator style={{ width: '50px', marginTop: '100px' }} />}
        {!loading && !category && !showResultsMessage && (
          <h1 className={css.desktopResultsMessage}>Results for "{decodeAmpersandOnUrl(query.q)}"</h1>
        )}
        {!loading && (
          <CatalogProducts
            maxItems={this.props.maxItems}
            products={products}
            usePriceDivider={usePriceDivider}
            query={query}
            configuration={configuration}
            defaultThumbnail={this.props.defaultThumbnail}
            productSortConfiguration={this.props.productSortConfiguration}
          />
        )}
        {this.props.configuration?.renderProps?.renderBottomSection &&
          this.props.configuration.renderProps.renderBottomSection()}
      </>
    )
  }

  public onClearFilter = (key: string, value?: string) => {
    const { category } = this.props
    const onRemoveFilter: any = has(this.props, ['configuration', 'events', 'onRemoveFilter'])
    if (onRemoveFilter) {
      onRemoveFilter(key, category, value)
    }
  }

  public renderActiveFilters = (filters: IFilterItem[]) => {
    return (
      <div className={nCss.filtersActiveContainer}>
        {filters.map((filter: IFilterItem, index: number) => {
          return (
            <div key={index} className={nCss.filterContainer}>
              <div>
                <b>{filter.title}:</b>
                <span className={nCss.filterValue}>
                  {filter.value
                    .toLowerCase()
                    .replace('\\', '')
                    .replace(/^"|"$/g, '')}
                </span>
              </div>
              <div className={nCss.removeFilter} onClick={() => this.onClearFilter(filter.key, filter.value)}>
                <FontAwesomeIcon icon={faTimesCircle} className={nCss.removeFilter} />
              </div>
            </div>
          )
        })}
        <div className={nCss.clearFiltersButton}>
          <a onClick={() => this.onClearFilter('all')}>Clear filters</a>
        </div>
      </div>
    )
  }

  public render() {
    const { data, configuration, category, query, showResultsMessage } = this.props
    return (
      <Container>
        <Row>
          {!category && !showResultsMessage && (
            <h1 className={css.mobileMessage}>Results for "{decodeAmpersandOnUrl(query.q)}"</h1>
          )}
          <div>
            {this.props.filtersList && this.props.filtersList.length > 0 && (
              <div className={nCss.activeFilters}>{this.renderActiveFilters(this.props.filtersList)}</div>
            )}
          </div>
          <Col sm={3}>
            <CatalogNavigation
              category={this.props.category as any}
              attributes={data.navigation.attributes}
              configuration={configuration}
              filtersList={this.props.filtersList}
              productSortConfiguration={this.props.productSortConfiguration}
            />
          </Col>
          <Col sm={9}>{this.renderBody()}</Col>
        </Row>
      </Container>
    )
  }
}
