const colorHexMap = {
  Blue: '#00699A',
  Brown: '#826113',
  Gray: '#9B9B9B',
  // Add more color mappings as needed
}

const getColorHex = (color: string) => {
  // If the color string exists in the map, return its corresponding hex value
  if (colorHexMap.hasOwnProperty(color)) {
    return colorHexMap[color]
  }
  // If the color string starts with '#' and has 7 characters (including '#'), return it directly
  if (color[0] === '#' && color.length === 7) {
    return color
  }

  // Create a temporary div element to apply the color style
  const tempDiv = document.createElement('div')
  tempDiv.style.color = color

  // Append the div to the body to ensure styles are applied
  document.body.appendChild(tempDiv)

  // Get the computed color style
  const computedColor = window.getComputedStyle(tempDiv).color

  // Remove the temporary div from the DOM
  document.body.removeChild(tempDiv)

  // Convert the computed color to hexadecimal
  const hexColor = rgbToHex(computedColor)

  return hexColor
}

const rgbToHex = (rgb: string) => {
  // Split the RGB values from the computed color string
  const rgbArray = rgb.match(/\d+/g).map(Number)

  // Convert each RGB value to hexadecimal and concatenate
  const hex =
    '#' +
    rgbArray
      .map(c => {
        const hex = c.toString(16)
        return hex.length === 1 ? '0' + hex : hex
      })
      .join('')

  return hex
}

export { getColorHex }
