import React, { useState } from 'react'
import css from './Sort.scss'
import { IProductsSortOption } from './Sort'

export interface ISortByDropdownProps {
  productSortConfiguration?: {
    options: IProductsSortOption[]
    active: string
  }
  onChange: (option: IProductsSortOption) => void
  style?: any
}

const SortByDropdown = (props: ISortByDropdownProps) => {
  const [open, setOpen] = useState(false)
  const { productSortConfiguration, onChange, style } = props

  const displayActiveSort = () => {
    let sortText = 'Sort By'
    if (productSortConfiguration) {
      for (const item of productSortConfiguration.options) {
        if (item.value.indexOf(productSortConfiguration.active) !== -1) {
          sortText = item.label
        }
      }
    }
    return sortText
  }

  const handleListDisplay = () => {
    setOpen(!open)
  }

  const handleOptionClick = (option: IProductsSortOption) => {
    if (productSortConfiguration && option) {
      onChange(option)
    }
    setOpen(false)
  }

  return (
    <div className={css.customSelectContainer} style={style || {}}>
      <div className={open ? `${css.selectedText} ${css.active}` : css.selectedText} onClick={handleListDisplay}>
        <b>{displayActiveSort()}</b>
      </div>
      {open && productSortConfiguration && (
        <ul className={css.selectOptions}>
          {productSortConfiguration.options.map((option: IProductsSortOption) => {
            return (
              <li
                className={css.customSelectOption}
                data-name={option.label}
                key={option.value}
                onClick={() => handleOptionClick(option)}
              >
                <div>
                  <b>{option.label}</b>
                </div>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default SortByDropdown
