import * as React from 'react'
import { AxiosResponse } from 'axios'
import { adaptProductRecommendationsResponse } from '../../../../utils/unbxd/unbxdHelpers'
import ProductDisplayContainer from './ProductDisplay'
import { getCookie } from '../../../../utils/cookie'
import appendQueryString from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/appendQueryString'
import { getUnbxdProductRecommendations } from '../../../../utils/unbxd/unbxdApiCalls'
import { IUnbxdProduct } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/unbxd/unbxdInterfaces'
import {
  onRecommendationsWidgetImpression,
  onUnbxdProductClick,
  PageType,
  Widget,
} from '../../../../utils/unbxd/unbxdController'
import { DATA_LAYER_LOCATION_ID } from '../../../../utils/dataLayerController'

export interface IRecsOptionsConfig {
  pageType: string
  catlevel1Name?: string
  id?: number
}

export interface IState {
  recommendations: any
  title: string
  requestId: string
}

export default class UnbxdRecommendationsData extends React.Component<any, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      recommendations: null,
      title: '',
      requestId: '',
    }
  }

  async componentDidMount() {
    const { pageType, catlevel1Name, id } = this.props.options

    let url: string = ''
    try {
      const uid = getCookie('unbxd.userId') || '1'

      url = appendQueryString(url, { pageType })
      url = appendQueryString(url, { uid })
      if (pageType === 'PRODUCT' && id) {
        url = appendQueryString(url, { id })
      }
      if (catlevel1Name) {
        url = appendQueryString(url, { catlevel1Name: `${encodeURIComponent(catlevel1Name)}` })
      }
      const response: AxiosResponse = await getUnbxdProductRecommendations(url)
      const recDataProducts = adaptProductRecommendationsResponse(
        response?.data?.widget1?.recommendations?.slice(0, 4) || [],
      )

      const productIds = recDataProducts.map(recommendation => recommendation.uniqueId)
      onRecommendationsWidgetImpression(PageType.HOME, Widget.WIDGET1, productIds)

      this.setState({
        recommendations: recDataProducts,
        title: response?.data?.widget1?.widgetTitle,
        requestId: response.headers['x-request-id'],
      })
    } catch (e) {
      console.log(e?.response?.data || e)
      return e
    }
  }

  onUnbxdEvent = (targetProduct: IUnbxdProduct, index: number) => {
    const uniqueId = targetProduct.parentId || targetProduct.uniqueId
    onUnbxdProductClick(uniqueId, index.toString(), this.state.requestId, PageType.HOME, Widget.WIDGET1)
  }

  public render() {
    if (!this.state.recommendations?.length) {
      return <div style={{ marginTop: '80px' }} />
    }
    return (
      <ProductDisplayContainer
        products={this.state.recommendations}
        triggerUnbxdEvent={this.onUnbxdEvent}
        title={this.state.title}
        itemsShown={4}
        pageName={DATA_LAYER_LOCATION_ID.HOMEPAGE_RECS_CAROUSEL}
      />
    )
  }
}
