import * as React from 'react'
import css from './CategoryThumbnails.scss'

import { ICategory } from '..'
import { ICatalogConfig } from '../Catalog'
import MerceGrid from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Grid/Grid'

export interface ICategoryThumbnailsContainerProps {
  categories: ICategory[]
  configuration: ICatalogConfig
  columns?: string
}

const CategoryThumbnails = (props: ICategoryThumbnailsContainerProps) => {
  const emptyClick = () => {
    // do nothing for now
  }
  const renderThumbnail = (item: any) => {
    const category: ICategory = item as ICategory
    const click: any =
      props.configuration.events && props.configuration.events.goToCategory
        ? props.configuration.events.goToCategory
        : emptyClick
    return (
      <React.Fragment>
        <div
          className={`${css.container} fadeIn`}
          onClick={click.bind(null, category)}
          onKeyPress={click.bind(null, category)}
          tabIndex={0}
        >
          <img
            className={css.articleImage}
            src={category.thumbnail || 'https://via.placeholder.com/300x200'}
            alt={category.title}
          />
        </div>
        <p>{category.title}</p>
      </React.Fragment>
    )
  }
  return (
    <MerceGrid
      items={props.categories}
      columns={props.columns || '3'}
      renderProps={{
        renderThumbnail,
      }}
    />
  )
}

export default CategoryThumbnails
