import * as React from 'react'
import ProductDisplayContainer from './ProductDisplay/index'
import { IDynamicProduct } from '../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/OmniPage/OmniFactory/DynamicAdWidget/DynamicAdWidget'
import AdWidgetItemContainer from './AdWidget/AdWidgetItem'
import { IRecsOptionsConfig } from './ProductDisplay/index'
import { IOmniPageSharedEvents } from '../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/OmniPage/Shared.context'
import UNBXDProductList, { IUNBXDProductListOptionsConfig } from './UnbxdProductList/UnbxdProductList'

const renderProductRecommendations = (options: { [x: string]: any }) => {
  const recOptions = options as IRecsOptionsConfig
  return <ProductDisplayContainer options={recOptions} />
}

const renderAdWidgetItemThumbnail = (product: IDynamicProduct) => {
  return <AdWidgetItemContainer product={product} />
}

const renderUNBXDProductList = (
  options: { [x: string]: any },
  _locale: string | undefined,
  events: IOmniPageSharedEvents | undefined,
) => {
  const recOptions = options as IUNBXDProductListOptionsConfig
  return <UNBXDProductList options={recOptions} events={events} />
}

export default {
  productRecommendations: renderProductRecommendations,
  renderAdWidgetItemThumbnail,
  unbxdProductList: renderUNBXDProductList,
}
