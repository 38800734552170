import * as React from 'react'
import css from './ChangeMyStoreModal.scss'
import Modal from '../../../Common/Modal'
import LocationContext, { ILocationContextProviderProps } from '../../Context/LocationContext'
import { IStoreLocation } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Locations/interfaces'
import { getDistanceToStoreInMiles, sortWarehouseList } from '../../../Utils/locationUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

export interface IChangeMyStoreModalProps {
  onClose: () => void
}

const ChangeMyStoreModal = ({ onClose }: IChangeMyStoreModalProps) => {
  const { myClosestStore, storeList, location, setMyStore } = React.useContext(
    LocationContext.Context,
  ) as ILocationContextProviderProps

  const handleSetAsMyStore = (store: IStoreLocation) => {
    setMyStore(store)
    onClose()
  }
  return (
    <Modal
      onClose={onClose}
      width="860px"
      height={window.matchMedia('(max-width: 767.98px)').matches ? '100%' : '500px'}
      isCloseTextBold={true}
      isTitleBackgroundGrey={false}
      isOverFlowing={true}
    >
      <div className={css.changeMyStore}>
        <FontAwesomeIcon icon={faMapMarkerAlt} className={css.geoIcon} title="Map Marker" focusable="false" />
        Change My Store
      </div>
      <div className={css.zipCode}>
        ZIP Code: <b>{myClosestStore?.zip || location?.zip}</b>
      </div>
      <div className={css.storeListContainer}>
        {storeList &&
          (() => {
            // Sort the store list by distance
            const sortedStores = sortWarehouseList(storeList, location)

            // Separate the current store and other stores
            const currentStore = sortedStores.find(store => myClosestStore?.name === store.name)
            const otherStores = sortedStores.filter(store => myClosestStore?.name !== store.name)

            // Combine current store first, then other stores
            const finalSortedStores = currentStore ? [currentStore, ...otherStores] : otherStores

            return finalSortedStores.map((store: IStoreLocation) => {
              return (
                <div className={css.storeListItem} key={store.name}>
                  <div className={css.storeListItemButtonContainer}>
                    {myClosestStore?.name !== store.name ? (
                      <button
                        type="button"
                        className={css.setAsMyStoreButton}
                        onClick={() => handleSetAsMyStore(store)}
                      >
                        Set as My Store
                      </button>
                    ) : (
                      <span className={css.myStore}>
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          className={`${css.geoIcon} ${css.mini}`}
                          title="Map Marker"
                          focusable="false"
                        />
                        My Store
                      </span>
                    )}
                  </div>
                  <div className={css.storeListItemLocation}>
                    {store.name}, {store.state}
                  </div>
                  <div className={css.storeListItemDistance}>
                    {getDistanceToStoreInMiles(location?.latLng || null, {
                      lat: store.latitude,
                      lng: store.longtitude,
                    })}
                  </div>
                </div>
              )
            })
          })()}
      </div>
    </Modal>
  )
}

export default ChangeMyStoreModal
