import * as React from 'react'
import { ICatalogConfig } from '../Catalog'
import SortRow, { IProductsSortOption } from './Sort/Sort'
import css from './Products.scss'
import { IProduct } from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/PDP'
import { has } from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/lodash'
import ThemedButton from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Button/Button'
import ProductThumbnailContainer from '../../Search/ProductThumbnailContainer'

export interface IProps {
  products: IProduct[]
  usePriceDivider?: boolean
  query: any
  configuration: ICatalogConfig
  maxItems?: number
  defaultThumbnail?: string
  productSortConfiguration?: {
    options: IProductsSortOption[]
    active: string
  }
}
export interface IState {
  availableProducts: IProduct[]
}

export default class CatalogProducts extends React.Component<IProps, IState> {
  public max: number = 20
  constructor(props: IProps) {
    super(props)
    this.state = {
      availableProducts: this.props.products || [],
    }
  }

  UNSAFE_componentWillReceiveProps = (incoming: any) => {
    this.updateProducts(incoming.products)
  }

  componentDidMount = () => {
    this.updateProducts(this.props.products)
  }

  public updateProducts = (products: any[]) => {
    this.setState({
      availableProducts: products,
    })
  }

  public addProductsToList = () => {
    let { availableProducts } = this.state
    const { products } = this.props
    if (products) {
      const beginRange: number = availableProducts.length - 1
      let endRange: number = beginRange + this.max
      if (endRange >= products.length - 1) {
        endRange = products.length - 1
      }
      const itemstoAdd: IProduct[] = products.slice(beginRange, endRange)
      availableProducts = [...availableProducts, ...itemstoAdd]
      this.setState({
        availableProducts,
      })
    }
  }

  public onFiltersChange = (option: IProductsSortOption): void => {
    const onSortChange: any = has(this.props, ['configuration', 'events', 'onProductSort'])
    if (onSortChange) {
      onSortChange(option)
    }
  }

  public renderLoadMoreButton = () => {
    const { availableProducts } = this.state
    const { products } = this.props
    if (products && availableProducts.length < products.length) {
      return (
        <div className={css.loadMoreContainer}>
          <ThemedButton
            onClick={this.addProductsToList}
            style={{
              width: '230px',
              marginBottom: '30px',
            }}
          >
            Load More ({this.max})
          </ThemedButton>
        </div>
      )
    }
    return <div />
  }

  public renderThumbnailsContainer = () => {
    const { availableProducts } = this.state
    return (
      <div className={css.grid}>
        {availableProducts.map((product: any, index: number) => {
          return (
            <ProductThumbnailContainer
              isSalePage={this.props?.query?.slug === 'sale-view-all'}
              isSearchPage={this.props?.query.q}
              key={index}
              index={index}
              parent={product}
              productGroup={product.variants}
              variantCount={product.variantCount}
              configuration={{
                events: {
                  getURLToProduct: this.props.configuration.events.getURLToProduct,
                  onUnbxdProductClick: this.props.configuration.events.onUnbxdProductClick,
                  onProductSort: this.props.configuration.events.onProductSort,
                },
              }}
            />
          )
        })}
        <div className={css.gridItemEmpty} key="empty-1"></div>
        <div className={css.gridItemEmpty} key="empty-2"></div>
      </div>
    )
  }
  public renderBodyContent = () => {
    const { availableProducts } = this.state
    const { products, query, productSortConfiguration, maxItems } = this.props
    if (products.length === 0) {
      return <div>No products found...</div>
    }
    return (
      <React.Fragment>
        <div className={`${productSortConfiguration ? '' : css.hideFilters}`}>
          <SortRow
            productSortConfiguration={productSortConfiguration}
            shownItems={availableProducts.length}
            maxItems={maxItems !== undefined ? maxItems : products.length}
            onChange={this.onFiltersChange}
            query={query}
          />
        </div>

        {this.renderThumbnailsContainer()}
        {this.renderLoadMoreButton()}
      </React.Fragment>
    )
  }
  public renderResultsTitle = () => {
    const { query } = this.props
    if (!query || !query.ct) {
      return '?'
    }
    return query.ct.toUpperCase()
  }
  public render() {
    return (
      <React.Fragment>
        <hr />
        <div className={css.productCoreContainer}>{this.renderBodyContent()}</div>
      </React.Fragment>
    )
  }
}
