import React, { useState } from 'react'
import Router from 'next/router'
import { AxiosResponse } from 'axios'

import { IOmniPageSharedEvents } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/OmniPage/Shared.context'
import { IBrowsePageProps } from '../../../../../pages/catalog'
import { ICategory, MerceCatalog } from '../../Layout/Catalog'
import {
  defaultProductSortOptions,
  getActiveSortOptionFromUrl,
  onProductSortSelected,
  formatProductFilter,
  encodeFilters,
  ISearch,
  IFacetFilter,
  adaptUnbxdProductVariants,
  appendCommonQueryStrings,
} from '../../../../utils/unbxd/unbxdHelpers'
import { IMorUnbxdProduct, adaptUnbxdFacetBrowse } from '../../../Utils/unbxdUtils'
import appendQueryString from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/appendQueryString'
import { maxProductsToLoad, merchantVisibility, storeAvailability } from '../../../../settings/variables'
import { getUnbxdCatalog } from '../../../../utils/unbxd/unbxdApiCalls'
import {
  IUnbxdProduct,
  IUnbxdSearchQueryResponse,
} from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/unbxd/unbxdInterfaces'
import { ICatalogNavigationItem, IFilterItem } from '../../Layout/Catalog/Navigation/Navigation'
import { get, has } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/lodash'
import { IProduct } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/PDP'
import { Col, Row } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Layout'
import LoadingIndicator from '../../../Common/LoadingIndicator/LoadingIndicator'
import NoResults from '../../Layout/Catalog/NoResults'
import { onPersistBrowsePage, useBrowsePageRestoration } from '../../../Utils/stateRestoration'
import { onUnbxdProductClick } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/Unbxd/UnbxdController'
import { IProductsSortOption } from '../../Layout/Catalog/Products/Sort/Sort'
import css from '../../../Tenant/Layout/Catalog/Catalog.scss'
import { buildUnbxdRequest, getURLToProduct } from '../../../../utils/pages/pagesUtil'
import Container from '../../../Common/Layout/Container'

export interface IUNBXDProductListOptionsConfig {
  slug: string
}

export interface IUNBXDProductList {
  options: IUNBXDProductListOptionsConfig
  events?: IOmniPageSharedEvents | undefined
}

const UNBXDProductList = (props: IUNBXDProductList) => {
  const { options } = props
  const [products, setProducts] = useState<IUnbxdProduct[]>([])
  const [results, setResults] = useState<IUnbxdSearchQueryResponse | null>(null)
  const [facets, setFacets] = useState<ICatalogNavigationItem[]>([])
  const [loadingMoreProducts, setLoadingMoreProducts] = useState<boolean>(false)
  const [selectedFacets, setSelectedFacets] = useState<IFacetFilter[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [search, setSearch] = useState<ISearch>({ filter: [], sort: '' })
  const [error, setError] = useState<any>()
  const [filtering, setFiltering] = useState<boolean>(false)

  useBrowsePageRestoration(
    () => {
      // if removing useBrowsePageRestoration, the following should be passed to a useEffect with deps [router.query]
      updateFilter()
    },
    {
      products,
      facets,
      results,
      setProducts,
      setFacets,
      setResults,
      search,
      setSearch,
      query: Router.router?.query,
      setLoading,
      setSelectedFacets,
    },
  )

  const getResults = async (query: any, req?: any) => {
    let returnedProps: IBrowsePageProps = {
      error: '',
      query,
      unbxdUrlDisplay: '',
      navigationCategories: [],
      template: null,
      category: null,
      products: [],
      facets: [],
      breadcrumbs: [],
      subcategories: [],
      canonicalUrl: '',
      results: null,
      clientIPAddress: '',
      requestId: '',
    }

    if (!query.slug) {
      return { ...returnedProps, error: 'A category slug must be present in your url...' }
    }

    try {
      let url: string = ''
      // pluck the slug and leave all the other queries in there
      const matching = { ...query }
      delete matching.slug

      try {
        returnedProps.page = query.slug
        url = appendQueryString(url, { p: query.slug })
        returnedProps = await buildUnbxdRequest(url, returnedProps, query, matching, req)
      } catch (e) {
        console.log(e?.response?.data || e)
        returnedProps.error = e.toString() // 'An issued has occurred with analytics server...'
      }
    } catch (e) {
      const errorMessage: string = has(e, ['response', 'data', 'error', 'msg']) || e.toString()
      returnedProps.error = errorMessage
    }
    return returnedProps
  }

  // --------------------------------------------------------------------------- EVENTS ------------------------------------------------------------------------
  const updateFilter = async () => {
    setLoading(true)
    setFiltering(true)
    const filters = { slug: options.slug, filter: search?.filter, sort: search?.sort }
    try {
      const response = await getResults(filters)
      if (!response.error) {
        onPersistBrowsePage(response.products, response.facets, response.results, search)
        setSelectedFacets(search?.filter)
        setProducts(response?.products || [])
        setFacets(response?.facets || [])
        setResults(response.results)
        setLoading(false)
      } else {
        console.log(response.error)
      }
    } catch (e) {
      console.log(e?.response?.data || e)
    }
  }

  const unbxdProductClick = (product: IProduct, index: number) => {
    const unbxdProduct = product as IUnbxdProduct
    if (unbxdProduct.parentId) {
      onUnbxdProductClick(unbxdProduct.parentId, index.toString(), '1234' || '')
    }
  }

  const onAttributeFilterClick = (targetQuery: string, label: string) => {
    const split: string[] = targetQuery.split('=')
    if (split?.length > 1) {
      const filter: string = `${split[0]}_uFilter`
      const filterValue: string = `"${split[1].replace('"', '\\"')}"`
      const value: string = `${filter}:${filterValue}`
      const parsed: ISearch = search
      const filterObject = { value: value, label: label }
      if (parsed.filter.some(item => item.value == filterObject.value)) {
        onRemoveFilter(filter, null, filterValue)
      } else {
        parsed.filter.push(filterObject)
      }
      setSearch(parsed)
      updateFilter()
    }
  }

  const onRemoveFilter = (key: string, _category?: any, value?: string) => {
    const queryObject: ISearch = search
    if (key === 'all') {
      queryObject.filter = []
    }
    queryObject.filter = queryObject?.filter?.filter((f: IFacetFilter) => {
      if (value) {
        return f?.value.indexOf(value?.replace(/"/g, '')) === -1
      }
      return f?.value.indexOf(key) === -1
    })
    setSearch(queryObject)
    updateFilter()
  }

  const onProductSort = async (sortBy: IProductsSortOption) => {
    const parsed: ISearch = onProductSortSelected(sortBy, search)
    setSearch(parsed)
    updateFilter()
  }

  const goToCategory = (targetCategory: ICategory) => {
    Router.push(`/catalog/${targetCategory.slug}/`)
  }

  const events: any = {
    getURLToProduct,
    onUnbxdProductClick: unbxdProductClick,
    onAttributeFilterClick,
    goToCategory,
    onRemoveFilter,
    onProductSort,
  }

  const onLoadMore = async () => {
    const existingResults = results
    if (existingResults) {
      setLoadingMoreProducts(true)
      let url: string = ''
      url = appendQueryString(url, { p: options.slug })
      url = appendQueryString(url, { version: 'V2' })
      url = appendQueryString(url, { pagetype: 'boolean' })
      const { start } = existingResults.response
      const facetsFilterApplied = existingResults?.searchMetaData?.queryParams?.filter?.filter(
        (f: string) => !f.includes(merchantVisibility) && !f.includes(storeAvailability),
      )
      url = appendCommonQueryStrings(url, start + maxProductsToLoad, facetsFilterApplied?.length > 0)
      if (existingResults?.searchMetaData?.queryParams?.filter) {
        const filter = encodeFilters(existingResults.searchMetaData.queryParams.filter)
        url = appendQueryString(url, { filter })
      }
      url = appendQueryString(url, { sort: existingResults.searchMetaData.queryParams.sort })

      try {
        const targetResponse: AxiosResponse = await getUnbxdCatalog(url)
        const newProducts: IUnbxdProduct[] = adaptUnbxdProductVariants(targetResponse.data)

        const newFacets: ICatalogNavigationItem[] = targetResponse.data.facets
          ? adaptUnbxdFacetBrowse(targetResponse.data.facets.text.list, newProducts as IMorUnbxdProduct[])
          : []
        const sortParam = existingResults.searchMetaData.queryParams.sort
        let concatProducts = products ? [...products, ...newProducts] : [...newProducts]
        if (sortParam) {
          const sortProperty: string[] = sortParam.split(' ')
          concatProducts = concatProducts.sort((a: IProduct, b: IProduct) => {
            return get(a, sortProperty[0]) - get(b, sortProperty[0])
          })
          if (sortProperty[1] == 'desc') {
            concatProducts = concatProducts.reverse()
          }
        }
        setProducts(concatProducts)
        setFacets(newFacets)
        setResults(targetResponse.data)
        onPersistBrowsePage(concatProducts, newFacets, targetResponse.data, search)
        setLoadingMoreProducts(false)
      } catch (e) {
        console.log(e)
        setError(e)
      }
    }
  }

  const renderBody = () => {
    const urlQueries: any = { ...options }
    delete urlQueries.slug

    const filters: IFilterItem[] = []
    if (selectedFacets) {
      const filter = selectedFacets
      if (Array.isArray(filter)) {
        for (const f of filter) {
          filters.push(formatProductFilter(f))
        }
      } else {
        filters.push(formatProductFilter(filter))
      }
    }

    const filteredProducts = products
    const filteredAttributes = facets

    if (filteredProducts == undefined) {
      return
    }

    if (!loading && !filteredProducts?.length) {
      return <NoResults showClearFilters={filtering} onClearFilters={(key: string) => onRemoveFilter(key)} />
    }

    const renderLoadMoreButton = () => {
      return (
        <>
          {loadingMoreProducts && <LoadingIndicator style={{ margin: '30px 0' }} />}
          {showLoadMoreButton && !loadingMoreProducts && (
            <div className={css.loadMore}>
              <div className={css.loadMoreButton} onClick={onLoadMore}>
                Load More
              </div>
            </div>
          )}
        </>
      )
    }

    return (
      <>
        <MerceCatalog
          loading={loading}
          category={null}
          showResultsMessage={true}
          query={options}
          maxItems={results !== null ? results.response?.numberOfProducts : maxProductsToLoad}
          configuration={{
            events,
          }}
          productSortConfiguration={{
            options: defaultProductSortOptions,
            active: getActiveSortOptionFromUrl(search),
          }}
          defaultThumbnail={'https://assets.vevano.com/categories/Optimized/MISC/ImageComingSoon_Thumbnail.jpg'}
          filtersList={filters}
          data={{
            navigation: {
              attributes: filteredAttributes,
            },
            subcategories: [],
            products: filteredProducts,
          }}
        />
        {renderLoadMoreButton()}
      </>
    )
  }

  if (error) {
    return <div />
  }

  const showLoadMoreButton: boolean = products
    ? results !== null && products?.length < results.response?.numberOfProducts
    : false

  return (
    <Container fluid={false}>
      <Row>
        <Col>{renderBody()}</Col>
      </Row>
    </Container>
  )
}

export default UNBXDProductList
