import React from 'react'
import navigationCss from './Navigation/Navigation.scss'

interface INoResultsProps {
  onClearFilters: (key: string) => void
  showClearFilters: boolean
}
const NoResults = (props: INoResultsProps) => {
  return (
    <div>
      No products could be found...
      {props.showClearFilters && (
        <div className={navigationCss.clearFiltersButton}>
          <a onClick={() => props.onClearFilters('all')}>Clear filters</a>
        </div>
      )}
    </div>
  )
}

export default NoResults
