import * as React from 'react'
import { AxiosResponse } from 'axios'
import { IDynamicProduct } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/OmniPage/OmniFactory/DynamicAdWidget/DynamicAdWidget'
import { IUnbxdProduct } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/unbxd/unbxdInterfaces'
import Loader from '../../../Common/Loader/Loader'
import css from '../ProductDisplay/ProductDisplay.scss'
import MorPriceDisplay from '../../Layout/PDP/MorPriceDisplay/MorPriceDisplay'
import { getUnbxdProduct } from '../../../../utils/unbxd/unbxdApiCalls'
import { fit, IMAGE_PROXY_TYPES } from '../../../../utils/imageProxy'
import appendQueryString from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/appendQueryString'

export interface IAdWidgetItemContainerProps {
  product: IDynamicProduct
}

interface IState {
  product: IUnbxdProduct | null
  loading: boolean
}

export default class AdWidgetItemContainer extends React.Component<IAdWidgetItemContainerProps, IState> {
  constructor(props: IAdWidgetItemContainerProps) {
    super(props)
    this.state = {
      loading: true,
      product: null,
    }
  }

  getThumbnail = (): string => {
    try {
      const { product } = this.state
      if (product?.images) {
        if (typeof product.images === 'string') {
          const images = JSON.parse(product.images)
          if (images?.items?.[0]?.url) {
            return fit(images.items[0].url, {
              size: '300',
              type: IMAGE_PROXY_TYPES.JPEG,
              quality: 50,
            })
          }
        }
      }
      return '/static/morPlaceholder.jpg'
    } catch (e) {
      return '/static/morPlaceholder.jpg'
    }
  }

  getAltText = (): string => {
    try {
      const { product } = this.state
      if (product?.images) {
        if (typeof product.images === 'string') {
          const images = JSON.parse(product.images)
          if (images?.items?.[0]?.alt) {
            return images.items[0].alt
          }
        }
      }
      return ''
    } catch (e) {
      return ''
    }
  }

  componentDidMount = async () => {
    try {
      let url: string = '?filter=' + encodeURIComponent(`sku:${this.props.product.sku}`)
      url = appendQueryString(url, { variants: 'false' })
      const productResponse: AxiosResponse = await getUnbxdProduct(url)

      this.setState({ product: productResponse.data.response.products?.[0], loading: false })
    } catch (e) {
      this.setState({ loading: false })
    }
  }

  public render() {
    if (this.state.loading) {
      return <Loader />
    }
    if (!this.state.product) {
      return <div>Unable to find product...</div>
    }

    return (
      <div className={css.thumbnailInner}>
        <div>
          <div
            className={css.imageContainer}
            style={{
              backgroundImage: `url(${this.getThumbnail()})`,
            }}
            role="img"
            aria-label={this.getAltText()}
          />
          <div className={css.thumbnailDetails}>
            <div className={css.productTitle}>
              <div>{this.state.product.title}</div>
            </div>
            <MorPriceDisplay product={this.state.product} justifyCenter showSeePriceInCartLabel />
          </div>
        </div>
      </div>
    )
  }
}
